import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Popovers are used to bring attention to specific user interface elements, typically to suggest an action or to guide users through a new experience.`}</p>
    <p>{`Two components make up a popover; the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` component controls the absolute positioning of the popover, and `}<inlineCode parentName="p">{`Popover.Content`}</inlineCode>{` renders the inner content of the popover as well as the caret.`}</p>
    <p>{`By default, the popover renders with absolute positioning, meaning it should usually be wrapped in an element with a relative position in order to be positioned properly. To render the popover with relative positioning, use the `}<inlineCode parentName="p">{`relative`}</inlineCode>{` property.`}</p>
    <p>{`It can be useful to give the `}<inlineCode parentName="p">{`Popover.Content`}</inlineCode>{` element a margin to help align the popover.`}</p>
    <h2>{`Default Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jxs",
        "metastring": "live",
        "live": true
      }}>{`<Relative>
  <Text textAlign="center" display="block">
    <ButtonPrimary>Hello!</ButtonPrimary>
  </Text>

  <Popover relative open={true} caret="top">
    <Popover.Content mt={2}>
      <Heading fontSize={2}>Popover heading</Heading>
      <Text as="p">Message about this particular piece of UI.</Text>
      <Button>Got it!</Button>
    </Popover.Content>
  </Popover>
</Relative>
`}</code></pre>
    <h2>{`Caret position`}</h2>
    <p><inlineCode parentName="p">{`Popover`}</inlineCode>{` supports various caret positions, which you can specify via the `}<inlineCode parentName="p">{`caret`}</inlineCode>{` property. This demo shows all the valid values for the prop. The default is `}<inlineCode parentName="p">{`top`}</inlineCode>{`. Note that the `}<inlineCode parentName="p">{`top-left`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-left`}</inlineCode>{`, `}<inlineCode parentName="p">{`top-right`}</inlineCode>{`, and `}<inlineCode parentName="p">{`bottom-right`}</inlineCode>{` values modify the horizontal alignment of the popover.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "live noinline",
        "live": true,
        "noinline": true
      }}>{`function PopoverDemo(props) {
  const [pos, setPos] = React.useState('top')
  const [open, setOpen] = React.useState(true)

  return (
    <Box>
      <Heading as="h3" fontSize={3}>Caret Position</Heading>
      <CaretSelector current={pos} onChange={setPos} />
      <Heading as="h3" fontSize={3}>Popover Visibility</Heading>
      <Box my={2}>
        <label>
          <input type="checkbox" value={open} checked={open}
            onChange={() => setOpen(open => !open)}/> Open
        </label>
      </Box>

      <Relative pt={4}>
        <Popover relative open={open} caret={pos}>
          <Popover.Content>
            <Heading fontSize={2}><code>{pos}</code> caret</Heading>
            <Text as="p">Message about this particular piece of UI.</Text>
            <Button onClick={() => setOpen(false)}>Got it!</Button>
          </Popover.Content>
        </Popover>
      </Relative>
    </Box>
  )
}

function CaretSelector(props) {
  const choices = [
    'top',         'bottom',      'left',         'right',
    'left-bottom', 'left-top',    'right-bottom', 'right-top',
    'top-left',    'bottom-left', 'top-right',    'bottom-right'
  ].map((dir) => (
    <label>
      <input key={dir} type='radio' name='caret' value={dir}
        checked={dir === props.current} onChange={() => props.onChange(dir)} /> {dir}
    </label>
))

  return (
    <Grid gridTemplateColumns="repeat(4, auto)" gridGap={3} my={2}>
      {choices}
    </Grid>
  )
}

render(<PopoverDemo />)
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`Popover`}</inlineCode>{` components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, and `}<inlineCode parentName="p">{`POSITION`}</inlineCode>{` system props. `}<inlineCode parentName="p">{`Popover.Content`}</inlineCode>{` components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h3>{`Popover`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'div'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`caret`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'top'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls the position of the caret. See below for the list of caret positions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls the visibility of the popover.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`relative`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set to true to render the popover using relative positioning.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Caret Positions`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`caret`}</inlineCode>{` prop can be one of the following values: `}<inlineCode parentName="p">{`top`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom`}</inlineCode>{`, `}<inlineCode parentName="p">{`left`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-left`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-right`}</inlineCode>{`, `}<inlineCode parentName="p">{`top-left`}</inlineCode>{`, `}<inlineCode parentName="p">{`top-right`}</inlineCode>{`, `}<inlineCode parentName="p">{`left-bottom`}</inlineCode>{`, `}<inlineCode parentName="p">{`left-top`}</inlineCode>{`, `}<inlineCode parentName="p">{`right-bottom`}</inlineCode>{`, or `}<inlineCode parentName="p">{`right-top`}</inlineCode>{`.`}</p>
    <h3>{`Popover.Content`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'div'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      